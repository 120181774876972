<template>
  <div class="service">

    <div class="container">
      <div class="row px-3 d-flex justify-content-between">
        <div class="col-sm-3 p-0 border border-bottom-0">
          <b-button to="/admin" variant="primary" block class="text-white rounded-0">
            <i class="fad fa-ticket-alt"></i><span class="pl-2">Usuario: Administrador</span>
          </b-button>
        </div>
        <div v-if="group_id == 5" class="col-sm-3 p-0 border border-bottom-0">
          <b-button to="/report" variant="white" block class="text-muted">
            <i class="fad fa-file"></i><span class="pl-2">Reportes</span>
          </b-button>
        </div>
      </div>
    </div>

    <div class="container">

      <hr class="mt-0">
      
      <div class="row px-4">
        <div class="col-sm-6 px-0">
          <div class="h5 text-primary">
            <span class="pr-2">Tickets de soporte</span>
            <b-button v-if="!new_ticket" v-on:click="show_new_ticket()" variant="white">
              <i class="fal fa-plus-circle"></i>
            </b-button>
          </div>
        </div>
      </div>

      <div v-if="new_ticket" :class="new_ticket_class" class="new_ticket mb-3">
        <div class="row px-3"><hr class="col-12 px-0"></div>
        <div class="row">
          <div class="col-sm-5">
            <b-form-input block v-model="customer_name" placeholder="Nombre del cliente"></b-form-input>
          </div>
          <div class="col-sm-3">
            <b-form-input block v-model="customer_phone" placeholder="Teléfono del cliente"></b-form-input>
          </div>
          <div class="col-sm-3">
            <b-form-input block v-model="customer_email" placeholder="Email del cliente"></b-form-input>
          </div>
          <div class="col-sm-1 text-right">
            <b-button block v-on:click="add_ticket()" variant="primary"><i class="fas fa-plus"></i></b-button>
          </div>
        </div>
        <div class="row px-3"><hr class="col-12 px-0"></div>
      </div>

      <b-list-group>
        <b-list-group-item v-on:click="show_ticket(item.id)" href="#" 
          :class="(show_ticket_id == item.id) ? show_ticket_class : ''" class="mb-3 border" v-for="(item, key) in tickets" :key="key">
          
          <div class="row">
            <div class="col-sm-6">
              <p class="mb-0 text-primary"><strong>Ticket</strong></p>
              <p class="mb-0"><span class="text-muted">Id:</span> {{ item.id }}</p>
              <p class="mb-0">
                <span :class="item.status_class" class="pr-2">
                  <i class="fas fa-circle"></i>
                </span>
                <span :class="item.status_class">{{ item.status }}</span>
              </p>
              <p class="mb-0"><span class="text-muted">Recibido:</span> {{ item.date_added }}</p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0 text-primary"><strong>Cliente</strong></p>
              <p class="mb-0"><span class="text-muted">Nombre:</span> {{ item.customer_name }}</p>
              <p class="mb-0">
                <span class="text-muted pr-1">Teléfono:</span> 
                <a v-on:click="send_whatsapp(item.customer_phone)" href="#">{{ item.customer_phone }}</a>
              </p>
              <p class="mb-0">
                <span class="text-muted pr-1">Email:</span>
                <a :href="'mailto:' + item.customer_email">{{ item.customer_email }}</a>
              </p>
            </div>
          </div>

          <div v-if="show_ticket_id == item.id" class="actions pt-2 animate__animated animate__fadeIn">
            
            <div class="product_list">
              <p v-if="item.product_list.length > 0" class="text-primary"><strong>Productos</strong></p>
              <div v-for="(product, pkey) in item.product_list" :key="pkey" class="px-2 bg-white rounded mb-2">
                <div class="row py-2">
                  <div class="col-sm-6">
                    <p class="mb-0"><strong>{{ product.type }} - {{ product.name }}</strong></p>
                    <p class="mb-0">{{ product.description }}</p>
                    <p class="mb-0" v-if="product.subtotal > 0">Precio: $ {{ product.subtotal }}</p>
                  </div>
                  <div class="col-sm-6 my-auto">
                    <b-button v-on:click="update_status(product.id, 'matrix')"
                      :variant="(product.status.matrix) ? 'success' : 'white'" class="py-1 border mr-2">
                      <span class="small"><i class="fal fa-garage"></i><span class="pl-2">En matríz</span></span>
                    </b-button>
                    <b-button v-on:click="update_status(product.id, 'provider')"
                      :variant="(product.status.provider) ? 'success' : 'white'" class="py-1 border mr-2">
                      <span class="small"><i class="fal fa-building"></i><span class="pl-2">En proveedor</span></span>
                    </b-button>
                    <b-button v-on:click="update_status(product.id, 'store')"
                      :variant="(product.status.store) ? 'success' : 'white'" class="py-1 border mr-2">
                      <span class="small"><i class="fal fa-store"></i><span class="pl-2">En sucursal</span></span>
                    </b-button>
                    <b-button v-on:click="update_status(product.id, 'shipped')"
                      :variant="(product.status.shipped) ? 'success' : 'white'" class="py-1 border mr-2">
                      <span class="small"><i class="fal fa-hand-holding-box"></i><span class="pl-2">Entregado</span></span>
                    </b-button>
                  </div>
                </div>
              </div>

              <div v-if="new_product" class="row py-2">
                <div class="col-sm-3"><b-form-input block v-model="product_name" placeholder="Nombre"></b-form-input></div>
                <div class="col-sm-6"><b-form-input block v-model="product_description" placeholder="Descripción"></b-form-input></div>
                <div class="col-sm-2"><b-form-input block v-model="product_subtotal" placeholder="Precio" :disabled="new_product_price"></b-form-input></div>
                <div class="col-sm-1"><b-button v-on:click="add_product(item.id)" variant="white" class="border" block><i class="fas fa-plus"></i></b-button></div>
              </div>
            </div>

            <div class="row pt-2 px-3">
              <b-button v-on:click="show_new_product('Servicio')" 
                variant="white" class="py-1 border mr-2">
                <span class="small"><i class="fal fa-plus"></i><span class="pl-2">Servicio</span></span>
              </b-button>
              <b-button v-on:click="show_new_product('Garantia')" 
                variant="white" class="py-1 border mr-2">
                <span class="small"><i class="fal fa-plus"></i><span class="pl-2">Garantía</span></span>
              </b-button>
              <b-button v-on:click="view_ticket(item.id)"
                variant="white" class="py-1 border mr-2">
                <span class="small"><i class="fal fa-eye"></i></span>
              </b-button>
              <!-- <b-button 
                variant="white" class="py-1 border mr-2">
                <span class="small"><i class="fal fa-edit"></i></span>
              </b-button> -->
              <b-button v-on:click="check_ticket(item.id)" 
                variant="white" class="py-1 border mr-2">
                <span class="small"><i class="fal fa-check"></i></span>
              </b-button>
            </div>
          </div>

        </b-list-group-item>
      </b-list-group>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import settings from '@/settings'

export default {
  name: 'Service',
  data() {
    return {
      tickets: '',
      new_ticket: '',
      new_ticket_class: '',
      new_product: '',
      new_product_price: '',

      customer_name: '',
      customer_phone: '',
      customer_email: '',

      product_name: '',
      product_description: '',
      product_subtotal: '',
      product_total: '',

      show_ticket_id: '',
      show_ticket_class: '',
    }
  },
  methods: {
    show_new_ticket() {
      this.new_ticket = true
      this.new_ticket_class = 'animate__animated animate__fadeIn'
    },
    show_ticket(id) {
      if(this.show_ticket_id != id) this.new_product = false
      this.show_ticket_id = id
    },
    show_new_product(type) {
      this.new_product = true
      if(type === 'Servicio') this.new_product_price = false
      else this.new_product_price = true
    },
    send_whatsapp(phone) {
      var url = 'https://wa.me/52' + phone
      window.open(url, "_blank")
    },
    view_ticket(id) {
      this.$router.push('/service/' + id)
    },
    async add_ticket() {
      if(
        this.customer_name != '' &&
        this.customer_phone != '' &&
        this.customer_email != ''
      ) {
        this.new_ticket = false
        this.new_ticket_class = ''

        var customer_name = this.customer_name.charAt(0) + this.customer_name.slice(1)

        axios.post(settings.api().endpoint + settings.api().path + '/add_ticket', {
          status: 'Iniciado',
          status_class: 'text-success',
          customer_name: customer_name,
          customer_phone: this.customer_phone,
          customer_email: this.customer_email,
          group_id: this.group_id
        }, {
          headers: { 
            xwt: this.token
          }
        }).then( () => { 
          this.customer_name = ''
          this.customer_phone = ''
          this.customer_email = ''
          this.get_tickets() 
        })
      }
    },
    async add_product(id) {
      if(
        this.product_name != '' &&
        this.product_description != '' &&
        (this.product_subtotal != '' || this.new_product_price)
      ) {
        this.new_product = false

        var name = this.product_name.charAt(0).toUpperCase() + this.product_name.slice(1)
        var description = this.product_description.charAt(0).toUpperCase() + this.product_description.slice(1)
        console.log(name, description)

        axios.post(settings.api().endpoint + settings.api().path + '/add_product', {
          ticket_id: id,
          type: (this.new_product_price) ? 'Garantía' : 'Servicio',
          name:  name,
          description: description,
          status: { matrix: 1, provider: 0, store: 0, shipped: 0 },
          subtotal: this.product_subtotal,
          total: this.product_total
        }, { headers: { xwt: this.token } }).then( () => { this.get_tickets() })

        this.product_name = ''
        this.product_description = ''
        this.product_subtotal = ''
        this.product_total = ''
      }
    },
    async update_status(product_id, status) {
      axios.post(settings.api().endpoint + settings.api().path + '/update_product', {
        product_id: product_id,
        update_data: {
          column: 'status',
          status: {
            matrix: (status == 'matrix') ? 1 : 0,
            provider: (status == 'provider') ? 1 : 0,
            store: (status == 'store') ? 1 : 0,
            shipped: (status == 'shipped') ? 1 : 0,
          }
        }
      }, { headers: { xwt: this.token } }).then( () => { this.get_tickets() })
    },
    async check_ticket(id) {
      axios.post(settings.api().endpoint + settings.api().path + '/update_ticket', {
        ticket_id: id,
        update_data: { status: 'Terminado', status_class: 'text-info' }
      }, { headers: { xwt: this.token } }).then( () => { this.get_tickets() })
    },
    async get_tickets() {
      axios.get(settings.api().endpoint + settings.api().path + '/get_tickets', { headers: { xwt: this.token } }).then( (r) => {
        this.tickets = r.data.data
        if(r.data.data.length > 0) this.show_ticket_id = r.data.data[0].id
      })
    },
  },
  mounted() {
    this.token = this.$store.getters.get_token
    this.group_id = this.$store.getters.get_customer['customer_group_id']
    
    this.tickets = []
    this.new_ticket = false
    this.new_ticket_class = ''
    this.new_product = false
    this.new_product_price = false

    this.customer_name = ''
    this.customer_phone = ''
    this.customer_email = ''

    this.product_name = ''
    this.product_description = ''
    this.product_subtotal = ''
    this.product_total = ''

    this.show_ticket_id = 0
    this.show_ticket_class = 'bg-light'

    if(this.group_id != 5 && this.group_id != 2 && this.group_id != 4) this.$router.push('/')

    this.get_tickets()
  },
}
</script>
